<template>
  <div class="page">
    <div class="list">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        :finishedText="finishedText"
        ref="list"
      >
        <van-swipe-cell v-for="(item, index) in listData" :key="index">
          <div class="item" @click="toDetail(item.id)">
            <div class="pic_box">
              <img v-if="item.avatar != ''" :src="item.avatar" alt="" />
              <img v-else src="./img/defult_avatar.png" alt="" />
            </div>
            <div class="name">{{ item.nickName }}</div>
            <div class="message">{{ item.applyReason }}</div>
            <div
              class="refuse_btn"
              v-if="item.auditStatus == 0"
              @click.stop="refuse(2, item.id)"
            >
              拒绝
            </div>
            <div
              class="agree_btn"
              v-if="item.auditStatus == 0"
              @click.stop="agree(1, item.id)"
            >
              同意
            </div>
          </div>
          <template #right>
            <div class="right_remove">
              <img
                @click="removeMessage(item.id)"
                src="./img/remove_message.png"
                alt=""
              />
            </div>
          </template>
        </van-swipe-cell>
      </v-list>
    </div>
    <v-dialog
      confirmButtonColor="#FE9615"
      v-model="isDialog"
      @confirm="confirm"
    >
      <p class="dialog_text">{{ dialog_text }}？</p>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getApplyMessageUrl, auditUrl, removeAppInfoUrl } from "./api.js";
export default {
  name: "communityApplicationInformation",
  data() {
    return {
      finishedText: "没有更多了",
      finished: false,
      listData: [],
      requestData: {
        curPage: 1,
        pageSize: 10,
      },
      isDialog: false,
      dialog_text: "",
      id: null,
      auditStatus: null,
      itemId: null,
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {
    this.id = this.$route.query.id;
  },
  methods: {
    onLoad() {
      this.getList();
    },
    getList() {
      let params = {
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        associationId: this.id,
      };
      this.$axios
        .get(`${getApplyMessageUrl}`, {
          params: params,
        })
        .then((res) => {
          if (res.code === 200) {
            if (res.data.pages <= res.data.current) {
              this.finished = true;
            } else {
              this.$refs.list._data.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              return;
            }
            res.data.records.forEach((item) => {
              this.listData.push(item);
            });
          } else {
          }
        });
    },
    init() {
      this.requestData.curPage = 1;
      this.listData = [];
      this.$refs.list._data.loading = true;
      this.finished = false;
      this.getList();
    },
    toDetail(id) {
      this.$router.push({
        name: "communityApplicationInformationDetail",
        query: {
          id: id,
        },
      });
    },
    refuse(status, id) {
      this.auditStatus = status;
      this.itemId = id;
      this.dialog_text = "是否拒绝该用户加入";
      this.isDialog = true;
    },
    agree(status, id) {
      this.auditStatus = status;
      this.itemId = id;
      this.dialog_text = "是否同意该用户加入";
      this.isDialog = true;
    },
    removeMessage(id) {
      this.itemId = id;
      this.dialog_text = "是否确认移除该消息";
      this.isDialog = true;
    },
    confirm() {
      if (this.dialog_text == "是否确认移除该消息") {
        let params = {
          id: this.itemId,
        };
        this.$axios
          .post(`${removeAppInfoUrl}`, this.$qs.stringify(params))
          .then((res) => {
            if (res.code === 200) {
              this.$toast(res.msg);
              this.init();
            }
          });
      } else {
        let params = {
          id: this.itemId,
          auditStatus: this.auditStatus,
          auditId: this.userId,
        };
        this.$axios
          .post(`${auditUrl}`, this.$qs.stringify(params))
          .then((res) => {
            if (res.code === 200) {
              this.$toast(res.msg);
              this.init();
            }
          });
      }

      this.isDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  width: 100%;
  min-height: 100vh;
  padding-top: 26px;
  box-sizing: border-box;
  overflow-x: hidden;
  background-color: #fafafa;

  .dialog_text {
    font-size: 32px;
    font-weight: 600;
    color: #333333;
    text-align: center;
    padding: 86px 124px 58px;
    white-space: nowrap;
  }

  .right_remove {
    height: 100%;
    background: #fafafa;
    padding: 0 22px;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    img {
      width: 74px;
      height: 74px;
    }
  }

  .list {
    width: 100%;
    padding: 0 28px;
    box-sizing: border-box;

    .van-swipe-cell {
      overflow: hidden;
      margin-bottom: 18px;
      // box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.5);
    }

    .item {
      width: 100%;
      height: 220px;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.5);
      border-radius: 16px;
      position: relative;

      .refuse_btn {
        position: absolute;
        bottom: 0;
        right: 236px;
        width: 194px;
        height: 62px;
        box-shadow: 0px 4px 8px 0px rgba(225, 225, 225, 0.5);
        border-radius: 16px 0px 16px 0px;
        border: 2px solid;
        line-height: 62px;
        text-align: center;
        font-size: 26px;
        color: #fe9616;
      }

      .agree_btn {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 194px;
        height: 62px;
        background: linear-gradient(180deg, #ffc055 0%, #fe9615 100%);
        box-shadow: 0px 4px 8px 0px rgba(225, 225, 225, 0.5);
        border-radius: 16px 0px 16px 0px;
        line-height: 62px;
        text-align: center;
        font-size: 26px;
        color: #ffffff;
      }

      .message {
        width: 450px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 30px;
        color: rgba(0, 0, 0, 0.85);
        position: absolute;
        top: 76px;
        left: 154px;
      }

      .name {
        position: absolute;
        top: 32px;
        left: 154px;
        font-size: 26px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
      }

      .pic_box {
        position: absolute;
        top: 28px;
        left: 32px;
        width: 94px;
        height: 94px;
        border-radius: 50%;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
